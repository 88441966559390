export default {
  methods: {
    currency (val) {
      return this.$options.filters.currency(val)
    },
    date (val) {
      return this.$options.filters.date(val)
    }
  }
}
