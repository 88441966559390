<template>
  <div class="q-year-month row">
    <q-field class="col">
      <q-select v-model.trim="month" :options="months" @input="updateValue" label="Month" outlined/>
    </q-field>
    <div class="col-xs-1"></div>
    <q-field class="col" >
      <q-select v-model.trim="year" :options="years" @input="updateValue" label="Year" outlined/>
    </q-field>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'QYearMonth',
  props: ['value'],
  data () {
    return {
      years: this.generateYears(),
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      year: '',
      month: ''
    }
  },
  methods: {
    updateValue () {
      var val = this.year && this.month ? moment([Number(this.year), Number(this.month) - 1, 1]) : null
      this.$emit('input', val)
    },
    generateYears () {
      var now = moment()
      var years = []

      for (let i = moment(); i.year() <= now.year() + 15; i = i.add(1, 'year')) {
        years.push(String(i.year()))
      }

      return years
    }
  }
}
</script>

<style>
  .q-year-month {
    width: 100%;
    display: flex;
  }
</style>
