<template>
    <div class="address-editor">
      <div class="postcode">
        <q-input bottom-slots :value="postcode" @input="val => postcode = val.toUpperCase()"
                 @keydown.enter="search"
                 name="postcode"
                 label="Postcode"
                 maxlength="6"
                 dense>
<!--        <template v-slot:before>-->
<!--          <q-icon name="home" />-->
<!--        </template>-->

        <template v-slot:append>
          <q-icon class="search-btn" name="search" @click="search" />
        </template>
        </q-input>
        <span class="text-red" v-if="invalidPostcode" style="position: relative; bottom:10px;">Invalid Postcode</span>
      </div>

      <div class="address row flex-block items-center" v-if="address">
        <q-select class="number-select" name="number" v-model="number" label="Number" :options="numbers"></q-select>
        <p>{{address.street}}, {{address.town}}, {{address.county}}, {{address.postcode}}</p>
        <q-btn v-if="saveButton" class="save-address-btn" @click="saveAddress">Save</q-btn>
      </div>

    </div>
</template>

<script>
export default {
  name: 'AddressEditor',
  props: {
    saveButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      postcode: '',
      invalidPostcode: false,
      address: null,
      number: null
    }
  },
  mounted () {
    if (!this.saveButton) {
      this.$watch('number', () => {
        this.$emit('selected', {
          postcode: this.postcode,
          address: this.address,
          number: this.number
        })
      })
    }
  },
  computed: {
    numbers () {
      if (!this.address) {
        return []
      }

      return this.address.number.split(';')
    }
  },
  methods: {
    async saveAddress () {
      await this.$dispatch({
        action: 'customer/updateCurrentAddress',
        params: {
          address: this.address,
          number: this.number
        },
        message: 'Updating Current Address...',
        successMessage: 'Address updated.'
      })

      this.$emit('saved')
    },
    async search () {
      var postcodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
      if (postcodeRegex.test(this.postcode)) {
        this.invalidPostcode = false
        var data = await this.$dispatch({
          action: 'customer/searchAddressByPostcode',
          params: {
            postcode: this.postcode
          },
          message: 'Searching...',
          successMessage: 'Please select house number.'
        }, true)

        this.address = data.results
        this.number = this.numbers[0]
      } else {
        this.invalidPostcode = true
        setTimeout(() => {
          this.invalidPostcode = false
        }, 4000)
      }
    }
  }
}
</script>

<style scoped>
  .postcode {
    width:200px;
  }

  .search-btn {
    cursor: pointer;
  }

  .number-select {
    width:100px
  }

  @media (min-width: 700px) {
    .address p {
      margin:0;
    }

    .save-address-btn {
      margin-left:10px;
    }
  }
</style>
