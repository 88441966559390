<template>
  <q-card class="my-card">
    <form @submit.prevent="addCard">
      <q-card-section class="text-center">
        <br>
        <q-icon name="credit_card" color="light-blue-5" size="md"/>
        <br>
        <span class="text-h6"> Add Card</span>
        <br>
      </q-card-section>

      <q-card-section>
        <q-field >
          <q-select v-model.trim="$v.form.card_type_id.$model" :options="cardTypes" label="Card Type" helper="Plese select" outlined />
        </q-field>
        <span class="text-red" v-if="submitted && $v.form.card_type_id.$invalid">Please select a card type</span>

        <br>
        <q-field>
          <q-input type="text" v-model.trim="$v.form.number.$model" label="Card Number" outlined>
            <template v-slot:append>
              <q-icon name="credit_card" class="cursor-pointer" />
            </template>
          </q-input>
        </q-field>
        <span class="text-red"
              v-if="submitted && $v.form.number.$invalid">Please enter a 16 digit number</span>
        <br>
        <div class="row">
          <q-year-month class="col-xs-8" v-model="$v.form.expiry.$model"/>
          <div class="col-xs-1"></div>
          <q-field class="col-xs-3">
            <q-input type="text" v-model.trim="$v.form.cvv.$model" label="CVV" outlined/>
          </q-field>
        </div>
        <span class="text-red text-right"
              v-if="submitted && $v.form.cvv.$invalid">Please enter a 3 digit number </span>
        <span class="text-red"
              v-if="submitted && !$v.form.expiry.required"><br>Please select an expiry date</span>

        <span class="text-red"
              v-if="submitted && $v.form.cvv.$invalid"><br>Please enter cvv number</span>

        <span class="text-red"
              v-else-if="submitted && !$v.form.expiry.validExpiry">Please select an expiry date greater than this month</span>

        <br>
        <address-editor v-if="showAddressPicker" :save-button="false" @selected="handleAddressInput"></address-editor>

        <q-field borderless>
          <q-item class="no-padding" tag="label">
            <q-item-section avatar>
              <q-checkbox v-model="form.is_default" size="lg" color="light-blue-5"/>
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-light-blue-5 text-subtitle2">Use as primary payment method</q-item-label>
            </q-item-section>
          </q-item>
        </q-field>

      </q-card-section>
      <q-card-actions class="row full-width">
        <q-btn rounded class="col text-light-blue-7 outlineColor" outline label="Cancel" @click="resetForm" v-close-popup/>
        <q-btn   class="col bgBtnLiner text-white" rounded type="submit" label="Done"/>
      </q-card-actions>
      <br>
    </form>
  </q-card>
</template>

<script>
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'
import { QCheckbox } from 'quasar'
import QYearMonth from './QYearMonth'
import moment from 'moment'
import AddressEditor from './AddressEditor.vue'
const validExpiry = (value) => !value || value >= (moment().date(1).startOf('day'))

export default {
  name: 'AddCreditCard',
  props: ['value'],
  components: { QYearMonth, QCheckbox, AddressEditor },
  data () {
    return {
      showAddressPicker: false,
      cardTypes: [
        {
          label: 'Visa Debit',
          value: 1
        },
        {
          label: 'Visa Electron',
          value: 3
        },
        {
          label: 'Switch / Maestro',
          value: 5
        },
        {
          label: 'Mastercard',
          value: 6
        }
      ],
      submitted: false,
      form: {
        number: '',
        cvv: '',
        card_type_id: '',
        expiry: '',
        is_default: true,
        address: null
      }
    }
  },
  validations: {
    form: {
      card_type_id: {
        required
      },
      number: {
        required,
        minLength: minLength(16),
        maxLength: maxLength(16),
        numeric
      },
      cvv: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(3),
        numeric
      },
      expiry: {
        required,
        validExpiry
      }
    }
  },
  mounted () {
  },
  methods: {
    handleAddressInput (e) {
      this.form.address = {
        postcode: e.postcode,
        town: e.address.town,
        county: e.address.county,
        street: e.address.street,
        number: e.number
      }
    },
    async addCard () {
      if (this.$v.form.$invalid) {
        this.submitted = true
        return false
      }

      var success = await this.$dispatch({
        action: 'cards/addCard',
        params: this.form,
        message: 'Adding Card. This may take a minute',
        successMessage: 'Your card was added'
      })

      if (success) {
        window.dataLayer.push({ event: 'added_card' })
        this.$emit('success', true)
      } else {
        this.showAddressPicker = true
      }
    },
    resetForm () {
      this.submitted = false
      this.$v.form.$reset()
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>
