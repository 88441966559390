<template>
    <q-card class="my-card" :class="loan.isOpen() ? '' : 'text-grey-5'">
      <q-card-section class="no-padding">
        <q-item>
          <q-item-section side>
            <q-icon :name="loan.isOpen() ? 'cp_checked_shield_grey' : 'cp_checked_shield_green'"/>
          </q-item-section>
          <q-item-section>
            <q-item-label lines="1" class="text-subtitle1 text-bold">Loan Reference #{{ loan.get('ref_number') }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-card-section>

       <q-card-section class="no-padding">
          <q-item>
            <q-item-section side>
              <q-icon name="cp_date_closed"/>
            </q-item-section>
            <q-item-section>
              <span class="text-subtitle1" :class="titleClass">Loan Date</span>
            </q-item-section>
            <q-item-section avatar class="text-bold text-subtitle1">
             {{ loan.get('loan_date') | date }}
            </q-item-section>
          </q-item>
        </q-card-section>

        <q-card-section class="no-padding">
          <q-item>
            <q-item-section side>
              <q-icon name="cp_loan_amount"/>
            </q-item-section>
            <q-item-section >
              <span class="text-subtitle1" :class="titleClass">Loan Amount</span>
            </q-item-section>
            <q-item-section avatar class="text-bold text-subtitle1">
              {{ loan.get('amount') | currency }}
            </q-item-section>
          </q-item>
        </q-card-section>

       <q-card-section class="no-padding" v-if="!loan.isOpen()">
          <q-item>
            <q-item-section side>
              <q-icon name="cp_loan_amount"/>
            </q-item-section>
            <q-item-section >
              <span class="text-subtitle1" :class="titleClass">Amount Repaid</span>
            </q-item-section>
            <q-item-section avatar class="text-bold text-subtitle1">
              {{ loan.total() | currency }}
            </q-item-section>
          </q-item>
        </q-card-section>

        <q-card-section class="no-padding">
          <q-item v-if="!loan.isOpen()">
            <q-item-section side>
              <q-icon name="cp_date_closed" color="light-blue-3"/>
            </q-item-section>
            <q-item-section >
              <span class="text-subtitle1" :class="titleClass">Date Closed</span>
            </q-item-section>
            <q-item-section avatar class="text-bold text-subtitle1">
              {{ loan.get('closed_date') | date }}
            </q-item-section>
          </q-item>

           <q-item class="justify-center">
            <q-btn class="bgBtnLiner text-white" @click="sendAgreementToEmail" rounded label="Email my Loan Agreement"/>
          </q-item>
        </q-card-section>

      <q-dialog :value="openModal"  @hide="openModal = false">
        <q-card class="text-center rounded-borders q-ma-lg-lg q-pa-lg-lg" style="border: cornflowerblue 2px solid">
          <q-card-section>
            <q-icon name="cp_coins" color="success" style="font-size: 50px"/>
            <br>
            <br>
            <span class="text-h6">Please check your email for your loan agreement.</span>
          </q-card-section>
          <q-card-actions align="center">
            <q-btn label="OK" style="min-width: 100px" rounded @click="openModal = false" />
          </q-card-actions>
        </q-card>
      </q-dialog>
  </q-card>
</template>

<script>

export default {
  name: 'Loan',
  props: {
    loan: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      openModal: false
    }
  },
  computed: {
    titleClass () {
      return this.loan.isOpen() ? 'text-light-blue-5 text-weight-medium ' : 'text-light-blue-3 text-weight-small'
    }
  },
  methods: {
    async sendAgreementToEmail () {
      this.sent = await this.$dispatch({
        action: 'applications/sendEdoc',
        message: 'We are processing your request...',
        successMessage: 'Please check your email for your loan agreement.',
        params: this.application().edoc().get('id')
      })

      if (this.sent) {
        this.openModal = true
      }
    },
    application () {
      return this.$store.getters['applications/getApplicationByRef'](this.loan.get('ref_number'))
    }
  }
}
</script>

<style scoped>

</style>
