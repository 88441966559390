export default {
  methods: {
    failedRequestNotification () {
      this.$q.notify({
        color: 'red-5',
        message: 'Sorry, there was a problem loading your data. Please make sure you have an internet connection',
        timeout: 6000,
        textColor: 'white',
        icon: 'warning'
      })
    }
  }
}
