<template>
  <q-btn-group rounded class="row full-width" push v-if="perPage < records">
    <q-btn class="col text-white" :class="value === 0 ? '' : 'bgBtnLiner'" :color="value === 0 ? 'grey' : ''" size="sm"
           label="Prev" @click="navTo(value - 1)" rounded :disable="value === 0"/>
    <q-btn class="col" :label="`page ${value + 1} of ${pages}`" size="sm" disable/>
    <q-btn class="col text-white" :class="value === pages - 1 ? '' : 'bgBtnLiner'"
           :color="value === pages - 1 ? 'grey' : ''" label="Next" @click="navTo(value + 1)" size="sm"
           :disable="value === pages - 1"/>
  </q-btn-group>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    records: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    pages () {
      return Math.ceil(this.records / this.perPage)
    }
  },
  methods: {
    navTo (page) {
      this.$emit('input', page)
    }
  }
}
</script>

<style scoped>

</style>
